export const TASK_SUMMARY = "UserTaskSummary";

export const SUSTAINABLE_USER_TASK = "SustainableUserTask";

export const DASHBOARD_DATA = "DashboardData";

export const SUSTAINABILITY_PARAMS = "SustainabilityParameters";

export const REGISTERED_USER_DATA = "RegisteredUserData";

export const USER_AUTH_DATA = "UserAuthData";

export const USER_ACCOUNT_DATA = "UserAccountData";

export const TASK_IMPACT = "TaskImpact";

export const TASK_USAGE_ENTRY = "TaskUsageEntry";

export const SUSTAINABLE_COMMUNITY_TASK_SUMMARY =
  "SustainableCommunityTaskSummary";

export const PERFORMED_TASK_DETAILS = "PerformedTasksDetails";
