// Status for display of colors
export const DEFAULT_STATUS = "default";

export const PRIMARY_STATUS = "primary";

export const SUCCESS_STATUS = "success";

export const INFO_STATUS = "info";

export const WARN_STATUS = "warn";

export const ERROR_STATUS = "error";

// Sizes for display
export const EXTRA_SMALL_SIZE = "extra-small";

export const SMALL_SIZE = "small";

export const NORMAL_SIZE = "normal";

export const MEDIUM_SIZE = "medium";

export const LARGE_SIZE = "large";

export const EXTRA_LARGE_SIZE = "extra-large";

// Status for signifying ongoing status for data saved locally
export const LOAD_SUCCESS = "LOAD_SUCCESS";

export const LOAD_PROGRESS = "LOAD_PROGRESS";

export const LOAD_ERROR = "LOAD_ERROR";

export const CACHE_CLEAR = "CACHE_CLEAR";

//Hierarchy for signyfiying different roles among the components
export const PARENT = "PARENT";

export const CHILD = "CHILD";

export const ANCESTOR = "ANCESTOR";

//Alignment options
export const ALIGN_HORIZONTAL = "ALIGN_HORIZONTAL";

export const ALIGN_VERTICAL = "ALIGN_VERTICAL";

export const ALIGN_RIGHT = "ALIGN_RIGHT";

export const ALIGN_LEFT = "ALIGN_LEFT";

export const ALIGN_START = "ALIGN_START";

export const ALIGN_END = "ALIGN_END";

export const ALIGN_CENTER = "ALIGN_CENTER";

export const ALIGN_BETWEEN = "ALIGN_BETWEEN";

export const ALIGN_STRETCH = "ALIGN_STRETCH";

//Ratios for display
export const ONE_THIRD = "ONE_THIRD";

export const TWO_THIRD = "TWO_THIRD";

export const ONE_FOURTH = "ONE_FOURTH";

export const THREE_FOURTH = "THREE_FOURTH";

export const FULL = "FULL";

export const HALF = "HALF";

//Display Medium corresponding to breakpoints
export const MOBILE = "MOBILE";

export const TABLET = "TABLET";

export const DESKTOP = "DESKTOP";

// input type constants
export const RADIO = "RADIO";

export const CHECKBOX = "CHECKBOX";

export const DAY_PICKER = "DAY_PICKER";

// Direction constants
export const RIGHT = "RIGHT";

export const LEFT = "LEFT";
