import { SustainableCommunityPaginatedTasks } from "../../../graphql/types/community-tasks";

export function transformCommunityTasksSummaryResponse(
  existing: SustainableCommunityPaginatedTasks | undefined,
  incoming: SustainableCommunityPaginatedTasks | undefined
): SustainableCommunityPaginatedTasks | undefined {
  if (!incoming) {
    return existing;
  }

  let existingTaskSummary: SustainableCommunityPaginatedTasks["tasksSummary"] =
    [];
  if (existing) {
    const { tasksSummary } = existing;
    if (Array.isArray(tasksSummary)) {
      existingTaskSummary = tasksSummary;
    }
  }

  let { tasksSummary: incomingTaskSummary } = incoming;
  if (!Array.isArray(incomingTaskSummary)) {
    incomingTaskSummary = [];
  }

  return {
    tasksSummary: existingTaskSummary.concat(incomingTaskSummary),
    // overwrite existing pagination variables
    nextPageKey: incoming.nextPageKey,
    __typename: incoming.__typename,
  };
}
