import { useEffect, useState } from "react";
import { SESSION_CACHE_TYPE } from "../utils/constants/app-common";
import {
  removeListeners,
  attachListeners,
  CacheData,
  readFromCache,
  CacheType,
} from "../utils/helpers/cache-listeners";
import { JavaScriptObject } from "../utils/types/app-types";

export type UseCacheUpdaterParams = {
  actionPerformer?: () => void;
  cacheKey: string;
  clientKey: string;
  cacheType?: CacheType;
};

export default function useCacheUpdater<T extends JavaScriptObject = {}>({
  cacheKey,
  clientKey,
  cacheType = SESSION_CACHE_TYPE,
  actionPerformer,
}: UseCacheUpdaterParams): CacheData<T> | undefined {
  const [cachedData, setCachedData] = useState<
    CacheData<JavaScriptObject> | undefined
  >(() =>
    readFromCache<JavaScriptObject>({
      cacheKey,
      cacheType,
    })
  );

  useEffect(() => {
    function cacheChangeHandler(cachedData?: CacheData<JavaScriptObject>) {
      setCachedData(cachedData);
    }

    attachListeners({ cacheKey, clientKey, cacheType }, { cacheChangeHandler });
    // Call the cache handler immediately once so that any updates done
    // during the effect period are passed on
    cacheChangeHandler(
      readFromCache<JavaScriptObject>({
        cacheKey,
        cacheType,
      })
    );

    if (typeof actionPerformer === "function") {
      actionPerformer();
    }

    return () =>
      removeListeners({
        cacheKey,
        clientKey,
        cacheType,
      });
  }, [cacheKey, clientKey, cacheType, actionPerformer]);

  if (!cachedData) {
    return undefined;
  }

  return cachedData as CacheData<T>;
}
