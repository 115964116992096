import { SESSION_CACHE_TYPE, WINDOW_CACHE_TYPE } from "../constants/app-common";
import { CACHE_CLEAR, LOAD_SUCCESS } from "../constants/component-configs";
import { JavaScriptObject } from "../types/app-types";

export type CacheData<T extends JavaScriptObject = {}> = {
  loadStatus: string;
  data?: T;
};

export type CacheType = typeof WINDOW_CACHE_TYPE | typeof SESSION_CACHE_TYPE;

type CacheUpdateListeners = {
  cacheChangeHandler: (params?: CacheData<JavaScriptObject>) => void;
};

type CacheListenerConfig = {
  cacheKey: string;
  clientKey: string;
  cacheType?: CacheType;
};

export type CacheWrite<T extends JavaScriptObject = {}> = {
  cacheKey: string;
  cacheType?: CacheType;
  data?: T;
  status?: CacheData<T>["loadStatus"];
  writePolicy?: "merge" | "overwrite";
};

type CacheReadConfig = {
  cacheKey: string;
  cacheType?: CacheType;
};

type NotifyListenerConfig = CacheReadConfig & {
  overRideStatus?: string;
};

type ClearCacheDataParams = {
  cacheType?: CacheType;
  notifyAllListeners?: boolean;
};

const WINDOW_CACHE_LISTENER = new Map<
  string,
  Map<string, CacheUpdateListeners>
>();

const SESSION_CACHE_LISTENER = new Map<
  string,
  Map<string, CacheUpdateListeners>
>();

export const WINDOW_CACHE = new Map<string, CacheData<JavaScriptObject>>();
export const SESSION_CACHE = new Map<string, CacheData<JavaScriptObject>>();

const getCacheListenerBasedOnType = (cacheType?: CacheType) => {
  if (cacheType === SESSION_CACHE_TYPE) {
    return SESSION_CACHE_LISTENER;
  }

  return WINDOW_CACHE_LISTENER;
};

const getCacheBasedOnType = (cacheType?: CacheType) => {
  if (cacheType === SESSION_CACHE_TYPE) {
    return SESSION_CACHE;
  }

  return WINDOW_CACHE;
};

export function attachListeners(
  { cacheKey, clientKey, cacheType = SESSION_CACHE_TYPE }: CacheListenerConfig,
  { cacheChangeHandler }: CacheUpdateListeners
) {
  const cacheListenerType = getCacheListenerBasedOnType(cacheType);

  let clientListeners = cacheListenerType.get(cacheKey);
  if (typeof clientListeners === "undefined") {
    clientListeners = new Map();
  }

  let listeners = clientListeners.get(clientKey);
  if (typeof listeners !== "undefined") {
    // Client has already attached listeners, ignore this value
    return;
  }

  listeners = { cacheChangeHandler };
  clientListeners.set(clientKey, listeners);
  cacheListenerType.set(cacheKey, clientListeners);
}

export function notifyListeners<T extends JavaScriptObject>({
  cacheKey,
  cacheType = SESSION_CACHE_TYPE,
  overRideStatus,
}: NotifyListenerConfig) {
  const cacheListenerType = getCacheListenerBasedOnType(cacheType);

  const clientListeners = cacheListenerType.get(cacheKey);
  if (typeof clientListeners === "undefined") {
    return;
  }

  for (const clientListener of clientListeners.values()) {
    let cachedData = readFromCache<T>({ cacheKey, cacheType });
    if (overRideStatus) {
      if (!cachedData) {
        // TS needs assigmnent at declaration, hence its done in two places
        cachedData = { loadStatus: overRideStatus };
      }

      cachedData.loadStatus = overRideStatus;
    }
    clientListener.cacheChangeHandler(cachedData);
  }
}

export function removeListeners({
  cacheKey,
  clientKey,
  cacheType = SESSION_CACHE_TYPE,
}: CacheListenerConfig) {
  const cacheListenerType = getCacheListenerBasedOnType(cacheType);

  const externalApiListeners = cacheListenerType.get(cacheKey);
  if (typeof externalApiListeners === "undefined") {
    return;
  }

  externalApiListeners.delete(clientKey);
  cacheListenerType.set(cacheKey, externalApiListeners);
}

export function readFromCache<T extends JavaScriptObject = {}>({
  cacheKey,
  cacheType = SESSION_CACHE_TYPE,
}: CacheReadConfig): CacheData<T> | undefined {
  const cacheMap = getCacheBasedOnType(cacheType);
  const cachedData = cacheMap.get(cacheKey);
  if (!cachedData) {
    return;
  }

  return {
    ...cachedData,
    data: cachedData.data as T,
  };
}

export function writeToCache<T extends JavaScriptObject = {}>({
  cacheKey,
  cacheType = SESSION_CACHE_TYPE,
  data,
  status = LOAD_SUCCESS,
  writePolicy = "merge",
}: CacheWrite<Partial<T>>) {
  const cacheMap = getCacheBasedOnType(cacheType);
  const cachedData = cacheMap.get(cacheKey);
  const { data: existingCachedData } = cachedData || {};
  let updatedData: JavaScriptObject | undefined = undefined;
  if (writePolicy === "merge") {
    updatedData = {
      ...existingCachedData,
      ...data,
    };
  } else if (writePolicy === "overwrite") {
    updatedData = data;
  }

  cacheMap.set(cacheKey, {
    loadStatus: status,
    data: updatedData,
  });

  notifyListeners<T>({
    cacheKey,
    cacheType,
  });
}

export function clearCacheData({
  cacheType = SESSION_CACHE_TYPE,
  notifyAllListeners,
}: ClearCacheDataParams = {}) {
  const cache = getCacheBasedOnType(cacheType);
  cache.clear();

  const listenerMap = getCacheListenerBasedOnType(cacheType);
  if (notifyAllListeners) {
    Array.from(listenerMap.keys()).forEach((cacheKey) => {
      notifyListeners({ cacheKey, overRideStatus: CACHE_CLEAR });
    });
  }
}
