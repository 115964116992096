export const ROOT_GET_COMMUNITY_TASKS_SUMMARY = "getCommunityTasksSummary";

export const ROOT_GET_COMMUNITY_TASK_DETAILS = "getSingleCommunityTask";

export const ROOT_GET_COMMUNITY_ACTIVITY_LIST = "getTaskActivityMapper";

export const ROOT_GET_DASHBOARD_DATA = "dashboard";

export const ROOT_GET_USER_TASKS_SUMMARY = "getUserTasks";

export const ROOT_GET_USER_TASK_DETAILS = "getUserTaskDetails";

export const ROOT_QUERY = "ROOT_QUERY";
