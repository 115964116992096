import { ROUTE_POLYFILL_MAP } from "../constants/navigation";
import { PolyFillCheckerItem } from "../types/app-types";
import { matchRoute } from "./route-match";

export function objectValuesPolyfillCheck() {
  return !Object.values;
}

export function intersectionObserverPolyfillCheck() {
  try {
    return !(
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype &&
      "isIntersecting" in window.IntersectionObserverEntry.prototype
    );
  } catch (error) {
    // Not in a browser environment
    return false;
  }
}

export function resizeObserverPolyfillCheck() {
  try {
    return !window.ResizeObserver;
  } catch (error) {
    // Not in a browser environment
    return false;
  }
}

export function getPolyFillForRoute(currentRoute: string): {
  polyFill: PolyFillCheckerItem[];
} {
  for (const [route, polyFillData] of ROUTE_POLYFILL_MAP) {
    if (matchRoute(route, currentRoute)) {
      return polyFillData;
    }
  }

  return {
    polyFill: [],
  };
}

export function getPolyFillForRoutePath(routePath: string) {
  return ROUTE_POLYFILL_MAP.get(routePath)?.polyFill || [];
}
