export function fetchPastSevenDaysFromToday() {
  const todaysDate = new Date();
  todaysDate.setHours(0, 0, 0, 0);

  const pastSevenDayStart = new Date();
  pastSevenDayStart.setHours(0, 0, 0, 0);

  pastSevenDayStart.setDate(todaysDate.getDate() - 6);
  return { pastSevenDayStart, todaysDate };
}

type GetDateFromStringifiedDateParams = {
  stringifiedDate: string | undefined | null;
};

export function getDateFromStringifiedDate({
  stringifiedDate,
}: GetDateFromStringifiedDateParams): Date | undefined {
  if (!stringifiedDate) {
    return undefined;
  }

  return new Date(stringifiedDate);
}

export function getDateWithNoTime(date: Date = new Date()) {
  const modifiedDate = new Date(date);
  modifiedDate.setHours(0, 0, 0, 0);
  return modifiedDate;
}
