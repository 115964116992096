import {
  CAREERS_URL,
  COMMUNITY_TASK_DETAILS_URL,
  COMMUNITY_TASK_UPDATE_PICK_URL,
  COMPANY_INFO_URL,
  FEEDBACK_URL,
  PRIVACY_POLICY_URL,
  USER_TASK_DETAILS_URL,
  USER_TASK_UPDATE_PERFORM_URL,
  COMMUNITY_URL,
  HOME_PAGE_URL,
} from "./page";
import {
  intersectionObserverPolyfillCheck,
  resizeObserverPolyfillCheck,
} from "../helpers/polyfill-checkers";
import { PolyFillCheckerItem } from "../types/app-types";
import {
  INTERSECTION_OBSERVER_POLYFILL_PATH,
  RESIZE_OBSERVER_POLYFILL_PATH,
} from "./polyfil-paths";

export const MISC_SECTION = [
  {
    title: "About",
    subLinks: [
      { name: "Company", url: COMPANY_INFO_URL },
      { name: "Careers", url: CAREERS_URL },
      { name: "Privacy policy", url: PRIVACY_POLICY_URL },
    ],
  },
  {
    title: "Support",
    subLinks: [
      {
        name: "Feedback",
        url: FEEDBACK_URL,
      },
      // TODO add later
      // {
      //   name: "Roadmap",
      //   url: "/roadmap",
      // },
    ],
  },
];

export const COMMUNITY_TASK_DETAILS_PAGE_URL = `${COMMUNITY_TASK_DETAILS_URL}/:taskId`;

export const COMMUNITY_TASK_UPDATE_PICK_PAGE_URL = `${COMMUNITY_TASK_UPDATE_PICK_URL}/:taskId`;

export const USER_TASK_UPDATE_PERFORM_PAGE_URL = `${USER_TASK_UPDATE_PERFORM_URL}/:taskId`;

export const USER_TASK_DETAILS_PAGE_URL = `${USER_TASK_DETAILS_URL}/:taskId`;

export const ROUTE_POLYFILL_MAP = new Map<
  string,
  { polyFill: PolyFillCheckerItem[] }
>([
  [
    COMMUNITY_URL,
    {
      polyFill: [
        {
          polyfillChecker: resizeObserverPolyfillCheck,
          polyfillScriptPath: RESIZE_OBSERVER_POLYFILL_PATH,
        },
      ],
    },
  ],
  [
    HOME_PAGE_URL,
    {
      polyFill: [
        {
          polyfillChecker: intersectionObserverPolyfillCheck,
          polyfillScriptPath: INTERSECTION_OBSERVER_POLYFILL_PATH,
        },
      ],
    },
  ],
]);
