import { useMutation } from "@apollo/client/react/hooks";
import { useEffect } from "react";
import { REFRESH_TOKEN_USER_SESSION } from "../../components/auth/queries/auth-management/auth-management-queries";
import { RefreshTokenResponse } from "../../graphql/types/user-session";
import { RefreshTokenInput } from "../../graphql/types/user-session-input";
import {
  LOAD_ERROR,
  LOAD_PROGRESS,
  LOAD_SUCCESS,
} from "../../utils/constants/component-configs";
import {
  completeUserLogout,
  getTokenFromNativeStorage,
  refreshTokenCheckProgress,
  saveToken,
} from "../../utils/helpers/auth-helper";

export default function TokenManager() {
  const [refreshToken, { client }] = useMutation<
    RefreshTokenResponse,
    RefreshTokenInput
  >(REFRESH_TOKEN_USER_SESSION);

  useEffect(() => {
    async function beginTokenRefresh() {
      refreshTokenCheckProgress({ status: LOAD_PROGRESS });
      const { refreshToken: savedRefreshToken } = getTokenFromNativeStorage();
      if (!savedRefreshToken) {
        refreshTokenCheckProgress({ status: LOAD_SUCCESS });
        return;
      }

      try {
        const result = await refreshToken({
          variables: {
            sessionData: { refreshToken: savedRefreshToken },
          },
          fetchPolicy: "no-cache",
          errorPolicy: "all",
        });
        if (!result?.data?.refreshToken) {
          completeUserLogout(client);
          refreshTokenCheckProgress({ status: LOAD_ERROR });
          return;
        }
        saveToken(result.data.refreshToken);
        refreshTokenCheckProgress({ status: LOAD_SUCCESS });
      } catch (refreshTokenError) {
        completeUserLogout(client);
        refreshTokenCheckProgress({ status: LOAD_ERROR });
      }
    }
    const intervalId = window.setInterval(
      beginTokenRefresh,
      Number(process.env.AUTH_TOKEN_TIMEOUT)
    );

    // Do it the first time to instantly load the token
    beginTokenRefresh();

    return () => {
      window.clearInterval(intervalId);
    };
  }, [refreshToken]);

  return null;
}
