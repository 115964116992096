import { SESSION_CACHE_TYPE } from "../constants/app-common";
import {
  GLOBAL_FILTERS_STORAGE_KEY,
  GLOBAL_FILTER_CACHE_KEY,
} from "../constants/search-filters";
import { GlobalFilters, GlobalFiltersStorage } from "../types/filter-types";
import { writeToCache } from "./cache-listeners";
import {
  getItemFromSessionStorage,
  setItemInSessionStorage,
} from "./storage-helpers";

export function persistFiltersToCacheAndNativeStorage(
  globalFilters: GlobalFilters
) {
  writeToCache<GlobalFilters>({
    cacheKey: GLOBAL_FILTER_CACHE_KEY,
    cacheType: SESSION_CACHE_TYPE,
    data: globalFilters,
    writePolicy: "overwrite",
  });

  setItemInSessionStorage(
    GLOBAL_FILTERS_STORAGE_KEY,
    JSON.stringify(globalFilters)
  );
}

export function getFiltersFromNativeStorage(): GlobalFilters | undefined {
  const stringifiedFilters = getItemFromSessionStorage(
    GLOBAL_FILTERS_STORAGE_KEY
  );
  if (!stringifiedFilters) {
    return undefined;
  }

  try {
    const filters = JSON.parse(stringifiedFilters);
    const savedFilters = filters as GlobalFiltersStorage;
    return {
      ...savedFilters,
      day: new Date(savedFilters.day),
    };
  } catch (filterParseError) {
    return undefined;
  }
}
