// Important: Separating this from the remaining filter config so that the icons
// don't get included in the bundle if any of these constants are referenced

export const IMPACT_CARBON = { key: "CARBON", label: "Carbon" };

export const IMPACT_WATER = { key: "WATER", label: "Water" };

export const IMPACT_COMBINED_WASTE = { key: "COMBINED_WASTE", label: "Waste" };

export const IMPACT_PLASTIC_WASTE = {
  key: "PLASTIC_WASTE",
  label: "Plastic waste",
};

export const IMPACT_MEDICAL_WASTE = {
  key: "MEDICAL_WASTE",
  label: "Medical waste",
};

export const IMPACT_DRY_WASTE = { key: "DRY_WASTE", label: "Dry waste" };

export const IMPACT_WET_WASTE = { key: "WET_WASTE", label: "Wet waste" };

export const IMPACT_E_WASTE = { key: "E_WASTE", label: "Electronic waste" };
