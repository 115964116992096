import React, { Component, PropsWithChildren } from "react";
import ErrorPage from "../error-page/ErrorPage";

type ErrorBoundaryProps = {};

type ErrorBoundaryState = {
  errorText?: string;
};

export default class ErrorBoundary extends Component<
  PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {};

  static getDerivedStateFromError(error: Error) {
    return { errorText: error.message };
  }

  render() {
    const { errorText } = this.state;
    if (errorText) {
      return <ErrorPage errorText={errorText} />;
    }

    return this.props.children;
  }
}
