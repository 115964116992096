import { joinArrayOfStrings } from "./array-helpers";

export function isServerEnv() {
  // Neither window nor self, exists. Server env for this app
  return typeof window === "undefined" && typeof self === "undefined";
}

export function createClasses(classList?: Array<string | null | undefined>) {
  return joinArrayOfStrings(classList || [], " ");
}
