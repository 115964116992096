import React from "react";
import Toast from "../../atoms/toast/Toast";

type PageRendererProps = {
  element: JSX.Element;
};

export default function onPageLoad() {
  return function PageRenderer({ element }: PageRendererProps) {
    return (
      <>
        {element}
        <Toast />
      </>
    );
  };
}
