import { ApolloError } from "@apollo/client/core";
import { GraphQLErrors } from "@apollo/client/errors";
import { RETRY_AFTER_API_ERROR_KEY } from "../constants/app-common";
import {
  CLIENT_ERROR_MSG,
  REQUEST_COMMUNICATION_ERROR_MSG,
  DATA_STORE_FORMAT_ERROR_MSG,
  DATA_STORE_UNREACHABLE_ERROR_MSG,
  REQUEST_TIMEOUT_ERROR_MSG,
  UNEXPECTED_BAD_REQUEST_ERROR_MSG,
  UNEXPECTED_INTERNAL_SERVICE_ERROR_MSG,
  UNKOWN_ERROR_MESSAGE,
  RESPONSE_COMMUNICATION_ERROR_MSG,
} from "../constants/error-message";
import { AUTH_PROVIDER_API_ERROR_KEY } from "../constants/login";
import {
  REQUEST_TIMEOUT,
  NETWORK_ERROR,
  RESPONSE_COMMUNICATION_ISSUE,
  REQUEST_COMMUNICATION_ISSUE,
  NO_ERROR_CODE_FOR_OPERATION,
  AUTHENTICATION_ERROR,
  DATA_STORE_UNREACHABLE_ERROR,
  EXTRACT_DATA_FROM_STORE_ERROR,
  UNEXPECTED_BAD_REQUEST_ERROR,
  UNEXPECTED_INTERNAL_SERVICE_ERROR,
} from "../error-codes/app-errors";
import {
  BaseProcessOperationError,
  OperationError,
  ProcessGraphQlQueryError,
} from "../types/app-types";

const COMMON_ERRORS_MAP = new Map<number, string>([
  [NETWORK_ERROR, CLIENT_ERROR_MSG],
  [REQUEST_TIMEOUT, REQUEST_TIMEOUT_ERROR_MSG],
  [RESPONSE_COMMUNICATION_ISSUE, RESPONSE_COMMUNICATION_ERROR_MSG],
  [REQUEST_COMMUNICATION_ISSUE, REQUEST_COMMUNICATION_ERROR_MSG],
  [NO_ERROR_CODE_FOR_OPERATION, UNKOWN_ERROR_MESSAGE],
  [DATA_STORE_UNREACHABLE_ERROR, DATA_STORE_UNREACHABLE_ERROR_MSG],
  [EXTRACT_DATA_FROM_STORE_ERROR, DATA_STORE_FORMAT_ERROR_MSG],
  [UNEXPECTED_BAD_REQUEST_ERROR, UNEXPECTED_BAD_REQUEST_ERROR_MSG],
  [UNEXPECTED_INTERNAL_SERVICE_ERROR, UNEXPECTED_INTERNAL_SERVICE_ERROR_MSG],
]);

type OperationErrorCheck = {
  operationError?: ApolloError;
  operationNames: Set<string>;
  errorExclusionMapping?: Map<string, Set<number>>;
  operationCommonErrorMap?: Map<number, string>;
  errorMessage?: string;
};

type CustomOperationErrorData = {
  errorCodes: number[];
  errorMetaData: {
    retryAfter?: number;
    authProvider?: string;
  };
};

type OperationErrorCheckResponse = {
  errorMessage?: string;
};

type CheckErrorCode = {
  errorCodeToCheck: number;
  operationError?: OperationError;
};

type CheckErrorCodes = {
  safeErrorCodes: Set<number>;
  operationError?: OperationError;
};

type GetOperationSpecificErrorParams = ProcessGraphQlQueryError;

type GetOperationSpecificErrorFromGraphQlErrorsParams = {
  graphQLErrors?: GraphQLErrors;
} & Pick<ProcessGraphQlQueryError, "operationName">;

type HasAuthenticationErrorParams = {
  graphQLErrors?: GraphQLErrors;
};

type ProcessQueryErrorForOperationParams<T> = {
  operationErrorCallback?: (operationError: OperationError) => T | undefined;
} & ProcessGraphQlQueryError;

type ProcessQueryErrorForOperationReturn<T> = BaseProcessOperationError | T;

function getErrorMessagesForCommonErrors(operationError?: OperationError) {
  if (!operationError) {
    return;
  }

  const { errorCodes } = operationError;
  for (const errorCode of errorCodes) {
    const messageHandler = COMMON_ERRORS_MAP.get(errorCode);
    if (!messageHandler) {
      continue;
    }

    return messageHandler;
  }
}

function getOperationSpecificErrorFromGraphQlErrors({
  graphQLErrors,
  operationName,
}: GetOperationSpecificErrorFromGraphQlErrorsParams):
  | OperationError
  | undefined {
  if (!graphQLErrors) {
    return;
  }

  const collapsedOperationError: OperationError = {
    errorCodes: new Set(),
    errorMetaData: new Map(),
    errorMessage: UNKOWN_ERROR_MESSAGE,
  };

  for (const graphQLError of graphQLErrors) {
    const { extensions = {} } = graphQLError;
    const operationError = extensions[
      operationName
    ] as CustomOperationErrorData;

    if (!operationError) {
      continue;
    }

    const { errorCodes, errorMetaData } = operationError;

    // default error code for matched operation
    collapsedOperationError.errorCodes.add(NO_ERROR_CODE_FOR_OPERATION);
    if (Array.isArray(errorCodes) && errorCodes.length > 0) {
      // error codes exist, remove the default value
      collapsedOperationError.errorCodes.delete(NO_ERROR_CODE_FOR_OPERATION);

      errorCodes.forEach((code) =>
        collapsedOperationError.errorCodes.add(code)
      );
    }

    //Error meta data is not in the required format, don't parse it
    if (errorMetaData && typeof errorMetaData === "object") {
      if ("retryAfter" in errorMetaData) {
        collapsedOperationError.errorMetaData.set(
          RETRY_AFTER_API_ERROR_KEY,
          errorMetaData.retryAfter
        );
      }

      if ("authProvider" in errorMetaData) {
        collapsedOperationError.errorMetaData.set(
          AUTH_PROVIDER_API_ERROR_KEY,
          errorMetaData.authProvider
        );
      }
    }
  }

  // No error code existed, no error found
  if (collapsedOperationError.errorCodes.size === 0) {
    return;
  }

  return collapsedOperationError;
}

function getOperationSpecificError({
  queryError,
  operationName,
}: GetOperationSpecificErrorParams): OperationError | undefined {
  if (!queryError) {
    return;
  }

  const { networkError, graphQLErrors } = queryError;
  if (networkError) {
    const networkErrorMessage = COMMON_ERRORS_MAP.get(NETWORK_ERROR);
    return {
      errorCodes: new Set([NETWORK_ERROR]),
      errorMessage: networkErrorMessage ? networkErrorMessage : "",
      errorMetaData: new Map(),
    };
  }

  return getOperationSpecificErrorFromGraphQlErrors({
    operationName,
    graphQLErrors,
  });
}

export function getErrorInfoIfEntireQueryFailed({
  operationError,
  errorExclusionMapping,
  operationCommonErrorMap,
  operationNames,
  errorMessage = UNKOWN_ERROR_MESSAGE,
}: OperationErrorCheck): OperationErrorCheckResponse {
  if (!operationError) {
    return {};
  }

  const { networkError, graphQLErrors } = operationError;

  if (networkError) {
    const networkErrorMessage = COMMON_ERRORS_MAP.get(NETWORK_ERROR);
    return {
      errorMessage: networkErrorMessage ?? errorMessage,
    };
  }

  const commonErrorsCountMap = new Map<number, number>();
  const processedOperationErrorNames = new Set<string>();
  graphQLErrors.forEach((graphQLError) => {
    const { extensions } = graphQLError;
    Object.keys(extensions || {}).forEach((operationName) => {
      const { errorCodes: queryErrors } = extensions[
        operationName
      ] as CustomOperationErrorData;
      if (!Array.isArray(queryErrors)) {
        return;
      }
      const queryErrorsSet = new Set(queryErrors);
      const errorsToExclude = errorExclusionMapping?.get(operationName);

      queryErrorsSet.forEach((code) => {
        // Current error code does not constitue a fatal failure
        if (errorsToExclude?.has(code)) {
          return;
        }

        // Valid error code, add the operation to the processed list
        processedOperationErrorNames.add(operationName);

        if (
          !(COMMON_ERRORS_MAP.has(code) || operationCommonErrorMap?.has(code))
        ) {
          return;
        }

        // Error code one of the common ones across all operations, add it to the count map
        let count = commonErrorsCountMap.get(code);
        if (typeof count !== "number") {
          count = 0;
        }

        count += 1;
        commonErrorsCountMap.set(code, count);
      });
    });
  });

  const minNumErrorsRequired = operationNames.size;
  const processedErrors = processedOperationErrorNames.size;

  // The number of processed errors is less than the minimum nunber of operations performed
  // hence the entire query never failed
  if (processedErrors < minNumErrorsRequired) {
    return {};
  }

  const messages: Set<string> = new Set();

  // Check how many error codes are common across all the operation errors
  // the minimum number of errors is the number of operations that the component has provided.
  // By checking at least as many, extra errors that may be encountered because of additional
  // operations that are added at the back-end can also be accounted for
  for (const [key, value] of commonErrorsCountMap) {
    if (value >= minNumErrorsRequired) {
      const message =
        COMMON_ERRORS_MAP.get(key) || operationCommonErrorMap?.get(key);
      if (typeof message === "string") {
        messages.add(message);
      }
    }
  }

  let queryErrorMessage = errorMessage;
  if (messages.size > 0) {
    queryErrorMessage = Array.from(messages).join("\n");
  }

  return {
    errorMessage: queryErrorMessage,
  };
}

export function hasAuthenticationError({
  graphQLErrors,
}: HasAuthenticationErrorParams) {
  return graphQLErrors?.some(({ extensions }) =>
    Object.keys(extensions).some((objProp) => {
      const extensionData = extensions[objProp] as CustomOperationErrorData;
      if (!extensionData || typeof extensionData !== "object") {
        return false;
      }

      if (!Array.isArray(extensionData.errorCodes)) {
        return false;
      }

      return extensionData.errorCodes.some(
        (errorCode) => errorCode === AUTHENTICATION_ERROR
      );
    })
  );
}

export function processQueryErrorForOperation<T = BaseProcessOperationError>({
  operationName,
  queryError,
  operationErrorCallback,
}: ProcessQueryErrorForOperationParams<T>): ProcessQueryErrorForOperationReturn<T> {
  const operationError = getOperationSpecificError({
    queryError,
    operationName,
  });

  if (!operationError) {
    return { errorMessage: undefined };
  }

  let errorMessage = getErrorMessagesForCommonErrors(operationError);
  if (errorMessage) {
    return { errorMessage };
  }

  if (operationErrorCallback) {
    const callbackError = operationErrorCallback(operationError);
    if (callbackError) {
      return callbackError;
    }
  }

  return {
    errorMessage: UNKOWN_ERROR_MESSAGE,
  };
}
