exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app/[...].tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-community-task-details-[id]-tsx": () => import("./../../../src/pages/community-task/details/[id].tsx" /* webpackChunkName: "component---src-pages-community-task-details-[id]-tsx" */),
  "component---src-pages-community-task-update-pick-[id]-tsx": () => import("./../../../src/pages/community-task/update-pick/[id].tsx" /* webpackChunkName: "component---src-pages-community-task-update-pick-[id]-tsx" */),
  "component---src-pages-community-tsx": () => import("./../../../src/pages/community.tsx" /* webpackChunkName: "component---src-pages-community-tsx" */),
  "component---src-pages-company-info-tsx": () => import("./../../../src/pages/company-info.tsx" /* webpackChunkName: "component---src-pages-company-info-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-user-task-add-task-tsx": () => import("./../../../src/pages/user-task/add-task.tsx" /* webpackChunkName: "component---src-pages-user-task-add-task-tsx" */)
}

