import {
  REFRESH_TOKEN_PROGRESS_CACHE_KEY,
  TOKEN_CACHE_KEY,
} from "../utils/constants/login";
import { getTokenDataFromCachedData } from "../utils/helpers/auth-helper";
import { RefreshTokenData, TokenData } from "../utils/types/app-types";
import useCacheUpdater from "./useCacheUpdater";

type UseTokenStatusParams = {
  clientKey: string;
};

export default function useTokenStatus({ clientKey }: UseTokenStatusParams) {
  const tokenData = useCacheUpdater<TokenData>({
    cacheKey: TOKEN_CACHE_KEY,
    clientKey,
  });
  const refreshTokenData = useCacheUpdater<RefreshTokenData>({
    cacheKey: REFRESH_TOKEN_PROGRESS_CACHE_KEY,
    clientKey,
  });
  const refreshOnMountInProgress = refreshTokenData?.data?.firstCheck;
  const tokenExists = !!getTokenDataFromCachedData(tokenData);
  // This check tells its consumer whether its safe to process auth options
  return tokenExists && !refreshOnMountInProgress;
}
