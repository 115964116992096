import { createRoot, hydrateRoot } from "react-dom/client";
import onAppInitLoad from "./src/init/page-wrappers/onAppInitLoad";
import onPageLoad from "./src/init/page-wrappers/onPageLoad";
import { onRouteChange } from "./src/services/user-journey-tracker";
import { loadPolyFillForCurrentRoute } from "./src/init/load-route-polyfill";
import { rehydrateFiltersToCache } from "./src/init/rehydrate-cache/rehydrate-filters";
import { rehydrateTokenToCache } from "./src/init/rehydrate-cache/rehydrate-token";
import { preventHistoryRestore } from "./src/init/override-browser-defaults/browser-history";
import { showLoaderWithDelay } from "./src/init/app-loader/AppLoader";
import {
  createQueryParams,
  isClientOnlyRoute,
} from "./src/utils/helpers/navigation-helper";
import { ERROR_TEXT_SEARCH_PARAM, ERROR_URL } from "./src/utils/constants/page";
import "./src/styles/reset.scss";
import "./src/styles/common-helpers.scss";

export const wrapRootElement = onAppInitLoad({ ssr: false });

export const wrapPageElement = onPageLoad();

export const onClientEntry = async () => {
  try {
    const cleanup = showLoaderWithDelay();
    rehydrateFiltersToCache();
    rehydrateTokenToCache();
    preventHistoryRestore();
    await Promise.all([loadPolyFillForCurrentRoute()]);
    cleanup();
  } catch (clientEntryError) {
    let errorText;
    if (clientEntryError instanceof Error) {
      errorText = clientEntryError.message;
    }

    window.location.href = `${ERROR_URL}${createQueryParams([
      { key: ERROR_TEXT_SEARCH_PARAM, value: errorText },
    ])}`;
  }
};

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  if (location?.pathname === prevLocation?.pathname) {
    return;
  }
  window.scrollTo(0, 0);
};

export const onRouteUpdate = ({ location }) => {
  onRouteChange({ currentRoute: location.pathname });
};

export const shouldUpdateScroll = () => false;

export const replaceHydrateFunction = () => (element, container) => {
  // For client routes, don't hydrate because the server HTML is invalid.
  // The server HTML is only used for re-direction
  if (isClientOnlyRoute()) {
    const root = createRoot(container);
    root.render(element);
    return;
  }
  hydrateRoot(container, element);
};
