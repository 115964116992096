import { gql } from "@apollo/client/core";

export const REFRESH_TOKEN_USER_SESSION = gql`
  mutation RefreshSessionToken($sessionData: RefreshTokenInput!) {
    refreshToken(input: $sessionData) {
      accessToken
      refreshToken
    }
  }
`;

export const LOGOUT_USER_SESSION = gql`
  mutation LogoutUserSession($sessionData: LogoutSessionInput!) {
    logoutSession(input: $sessionData) {
      success
    }
  }
`;
