import React from "react";
import {
  SUCCESS_STATUS,
  INFO_STATUS,
  PRIMARY_STATUS,
  WARN_STATUS,
  ERROR_STATUS,
  ALIGN_START,
  ALIGN_END,
  ALIGN_BETWEEN,
} from "../../utils/constants/component-configs";
import { string, node, func } from "prop-types";
import RowAlign from "../row-align/RowAlign";
import ColumnAlign from "../column-align/ColumnAlign";
import useTimer from "../../hooks/useTimer";
import { createClasses } from "../../utils/helpers/browser-helper";
import CloseCircleIcon from "../../assets/svgs/solid-circle-xmark.inline.svg";
import Button from "../button/Button";
import SVGIcon from "../svg-icon/SvgIcon";
import styles from "./Notification.module.scss";

type NotificationProps = {
  notificationType?: string;
  // Add in a use callback whenever possible for effeciency
  onClose: () => void;
  // Will only be applied for success notifications
  autoDismiss?: boolean;
  showDismissText?: boolean;
  extraClasses?: string;
};

export default function Notification({
  children,
  notificationType = SUCCESS_STATUS,
  onClose,
  autoDismiss,
  showDismissText,
  extraClasses,
}: React.PropsWithChildren<NotificationProps>) {
  let shouldStartTimer = false;
  if (autoDismiss) {
    shouldStartTimer = true;
  }

  const { secondsLeft } = useTimer({
    countdownSeconds: 3,
    startTimer: shouldStartTimer,
    onCountdownComplete: onClose,
  });

  let notificationClasses = `${styles.notification} ${styles.px3} ${styles.py3} ${styles.isSize7}`;
  switch (notificationType) {
    case SUCCESS_STATUS:
      notificationClasses += ` ${styles.isSuccess}`;
      break;
    case INFO_STATUS:
      notificationClasses += ` ${styles.isInfo}`;
      break;
    case PRIMARY_STATUS:
      notificationClasses += ` ${styles.isPrimary}`;
      break;
    case WARN_STATUS:
      notificationClasses += ` ${styles.isWarning}`;
      break;
    case ERROR_STATUS:
      notificationClasses += ` ${styles.isDanger}`;
      break;
    default:
      break;
  }

  if (extraClasses) {
    notificationClasses += ` ${extraClasses}`;
  }

  function notificationCloseClicked(
    event: React.MouseEvent<HTMLButtonElement>
  ) {
    event.preventDefault();
    event.stopPropagation();
    onClose();
  }

  return (
    <ColumnAlign classes={notificationClasses}>
      <RowAlign
        horizontalAlign={ALIGN_BETWEEN}
        verticalAlign={ALIGN_START}
        classes={styles.notificationTextContainer}
      >
        {children}
        <Button
          buttonClick={notificationCloseClicked}
          type="button"
          classes={createClasses([styles.closeButtonContainer, styles.ml1])}
        >
          <SVGIcon
            iconProps={{
              className: createClasses([styles.deleteButton]),
            }}
          >
            {(svgProps) => <CloseCircleIcon {...svgProps} />}
          </SVGIcon>
        </Button>
      </RowAlign>
      {showDismissText && shouldStartTimer && (
        <RowAlign
          horizontalAlign={ALIGN_END}
          classes={createClasses([styles.dismissText])}
        >
          <span>Closes in {secondsLeft} seconds</span>
        </RowAlign>
      )}
    </ColumnAlign>
  );
}

Notification.propTypes = {
  notificationType: string,
  onClose: func,
  children: node.isRequired,
};
