import { NATIVE_LOCAL_STORAGE_KEY } from "../constants/app-common";
import { NativeStorageTypes } from "../types/app-types";

export function getItemFromSessionStorage(itemName: string) {
  try {
    return window.sessionStorage.getItem(itemName);
  } catch (readStorageError) {
    //No-op aded to prevent error
  }
}

export function setItemInSessionStorage(itemName: string, itemValue: string) {
  try {
    return window.sessionStorage.setItem(itemName, itemValue);
  } catch (readStorageError) {
    //No-op aded to prevent error
  }
}

export function removeItemFromSessionStorage(itemName: string) {
  try {
    return window.sessionStorage.removeItem(itemName);
  } catch (readStorageError) {
    //No-op aded to prevent error
  }
}

export function getItemFromLocalStorage(itemName: string) {
  try {
    return window.localStorage.getItem(itemName);
  } catch (readStorageError) {
    //No-op aded to prevent error
  }
}

export function setItemInLocalStorage(itemName: string, itemValue: string) {
  try {
    return window.localStorage.setItem(itemName, itemValue);
  } catch (readStorageError) {
    //No-op aded to prevent error
  }
}

export function removeItemFromLocalStorage(itemName: string) {
  try {
    return window.localStorage.removeItem(itemName);
  } catch (readStorageError) {
    //No-op aded to prevent error
  }
}

export function getItemFromNativeStorageAsPerType(
  storageType: NativeStorageTypes,
  itemName: string
) {
  if (storageType === NATIVE_LOCAL_STORAGE_KEY) {
    return getItemFromLocalStorage(itemName);
  }

  return getItemFromSessionStorage(itemName);
}

export function setItemInNativeStorageAsPerType(
  storageType: NativeStorageTypes,
  itemName: string,
  itemValue: string
) {
  if (storageType === NATIVE_LOCAL_STORAGE_KEY) {
    setItemInLocalStorage(itemName, itemValue);
    return;
  }

  setItemInSessionStorage(itemName, itemValue);
}

export function clearNativeStorage() {
  window.localStorage.clear();
  window.sessionStorage.clear();
}
