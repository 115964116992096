export const LOGIN = "LOGIN";

export const SIGNUP = "SIGNUP";

export const GOOGLE_OAUTH = "GOOGLE";

export const FACEBOOK_OAUTH = "FACEBOOK";

export const EMAIL_SIGNUP = "EMAIL";

export const AUTH_PROVIDER_API_ERROR_KEY = "authProvider";

export const TOKEN_CACHE_KEY = "TOKEN";

export const OPERATIONS_IGNORE_TOKEN_CHECK = new Set(["RefreshSessionToken"]);

export const REFRESH_TOKEN_PROGRESS_CACHE_KEY = "REFRESH_TOKEN_PROGRESS";
