import React from "react";
import { FilterPageSectionData } from "../../molecules/filter/filter-page/FilterPage";
import {
  GlobalDateFilters,
  GlobalFilters,
  GlobalRadioFilters,
} from "../types/filter-types";
import { DAY_PICKER, RADIO } from "./component-configs";
import SmogIcon from "../../assets/svgs/solid-smog.inline.svg";
import { getDateWithNoTime } from "../helpers/date-helpers";
import { IMPACT_CARBON, IMPACT_WATER } from "./task-impact-common";
import { FilterDateSectionData } from "../../molecules/filter/filter-date/FilterDate";

export const FILTER_PAGE_TYPE = "FILTER_PAGE";

export const FILTER_DATE_TYPE = "FILTER_DATE";

export const FILTER_ALL_IMPACT_CATEGORY = "ALL_IMPACT_CATEGORY";

export const DEFAULT_FILTERS: GlobalFilters = {
  day: getDateWithNoTime(),
  impactCategory: FILTER_ALL_IMPACT_CATEGORY,
};

// TODO add more options as and when the parameters are supported
export const FILTER_IMPACT_MAPPER = new Map<string, string>([
  [FILTER_ALL_IMPACT_CATEGORY, "All"],
  [IMPACT_CARBON.key, IMPACT_CARBON.label],
  [IMPACT_WATER.key, IMPACT_WATER.label],
]);

export const IMPACT_FILTER_CONFIG: FilterPageSectionData<GlobalRadioFilters> = {
  type: FILTER_PAGE_TYPE,
  title: "Ecological impact",
  icon: (props) => <SmogIcon {...props} />,
  filterVariable: "impactCategory",
  inputType: RADIO,
  inputName: "impact-category",
  mapping: FILTER_IMPACT_MAPPER,
};

export const DAY_FILTER_CONFIG: FilterDateSectionData<GlobalDateFilters> = {
  filterVariable: "day",
  inputType: DAY_PICKER,
  title: "Choose date",
  type: FILTER_DATE_TYPE,
};
