import React from "react";
import useCacheUpdater from "../../hooks/useCacheUpdater";
import { DISPLAY_TOAST_CACHE_KEY } from "../../utils/constants/app-common";
import {
  DisplayToastCache,
  DisplayToastCacheItem,
} from "../../utils/types/app-types";
import Notification from "../notification/Notification";
import { removeToastNotification } from "../../utils/helpers/toast-helpers";
import ColumnAlign from "../column-align/ColumnAlign";
import styles from "./Toast.module.scss";
import { createClasses } from "../../utils/helpers/browser-helper";
import { ALIGN_STRETCH } from "../../utils/constants/component-configs";

type ToastProps = {};

export default function Toast({}: ToastProps) {
  const toastData = useCacheUpdater<DisplayToastCache>({
    cacheKey: DISPLAY_TOAST_CACHE_KEY,
    clientKey: "TOAST_COMPONENT",
  });

  if (!toastData || !toastData.data || toastData.data.toasts.length === 0) {
    return null;
  }

  function renderToastItem(toastItem: DisplayToastCacheItem) {
    return (
      <Notification
        autoDismiss
        onClose={() => removeToastNotification({ toastItemId: toastItem.id })}
        notificationType={toastItem.status}
        key={toastItem.id}
      >
        {toastItem.message}
      </Notification>
    );
  }

  const { toasts } = toastData.data;
  return (
    <ColumnAlign
      classes={createClasses([styles.toastContainer])}
      horizontalAlign={ALIGN_STRETCH}
    >
      {toasts.map(renderToastItem)}
    </ColumnAlign>
  );
}
