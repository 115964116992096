export const GOOGLE_OAUTH_URI = "https://accounts.google.com/gsi/client";

export const FACEBOOK_OAUTH_URI = "https://connect.facebook.net/en_US/sdk.js";

export const WINDOW_CACHE_TYPE = "WINDOW_CACHE_TYPE";

export const SESSION_CACHE_TYPE = "SESSION_CACHE_TYPE";

export const RETRY_AFTER_API_ERROR_KEY = "retryAfter";

export const SESSION_STORAGE_START_PAGE = "startPage";

export const NATIVE_SESSION_STORAGE_KEY = "NATIVE_SESSION_STORAGE";

export const NATIVE_LOCAL_STORAGE_KEY = "NATIVE_LOCAL_STORAGE";

export const DISPLAY_TOAST_CACHE_KEY = "DISPLAY_TOAST";

export const BLOCK_BODY_SCROLL_CSS_CLASS = "block-body-scroll";

export const TASK_DECIMAL_SUPPORT = 3;
