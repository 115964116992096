export const NETWORK_ERROR = 499;

/*
  If an operation fails but has no error code information associated to it then
  one of two things may happen. Either this error is coming directly from the
  graphql serivce indicating that something is worng with the schema validation,
  or that the operation faild but did not have any error code associated to it.
*/
export const NO_ERROR_CODE_FOR_OPERATION = 599;

/*
  This indicates that the operation command was sent and it was successful, but the data from
  some component did not come in the expected format and hence the retrieved data could not
  be unmarshalled. Indicates a bug in the system
*/
export const RESPONSE_COMMUNICATION_ISSUE = 900;

/*
  This indicates that the operation command was sent but it wasn't in the correct
  format data and hence could not be marshalled. Indicates a bug in the system
*/
export const REQUEST_COMMUNICATION_ISSUE = 901;

export const AUTHENTICATION_ERROR = 902;

export const REQUEST_TIMEOUT = 903;

export const DATA_STORE_UNREACHABLE_ERROR = 906;

export const EXTRACT_DATA_FROM_STORE_ERROR = 907;

export const UNEXPECTED_BAD_REQUEST_ERROR = 908;

export const UNEXPECTED_INTERNAL_SERVICE_ERROR = 909;
