import { matchRoute } from "../../utils/helpers/route-match";
import { JavaScriptObject } from "../../utils/types/app-types";

export type UserJourneyRoutes = Array<Array<string>>;

let startingRoute: string = "";
let currentJourneyRoutes: UserJourneyRoutes = [];
let currentJourneyClearCache: () => void = () => {};
let currentJourneyIndex: number = -1;
let currentJourneyData: JavaScriptObject | undefined = undefined;

function resetUserJourneyData() {
  currentJourneyClearCache();

  currentJourneyClearCache = () => {};
  currentJourneyIndex = -1;
  currentJourneyRoutes = [];
  currentJourneyData = undefined;
}

function haveRoutesChanged(routeJourneys: UserJourneyRoutes) {
  if (routeJourneys.length !== currentJourneyRoutes.length) {
    return true;
  }

  const routeChanged = routeJourneys.some((routes, journeyIndex) => {
    if (routes.length !== currentJourneyRoutes[journeyIndex].length) {
      return true;
    }

    return routes.some((route, index) => {
      const savedRoute = currentJourneyRoutes[journeyIndex][index];
      return savedRoute !== route;
    });
  });

  return routeChanged;
}

type OnRouteChangeParams = {
  currentRoute: string;
};

export function onRouteChange({ currentRoute }: OnRouteChangeParams) {
  if (currentRoute === startingRoute) {
    currentJourneyIndex = -1;
    return;
  }

  if (currentJourneyIndex === -1) {
    currentJourneyIndex = currentJourneyRoutes.findIndex((journeyRoutes) => {
      const foundJourneyIndex = journeyRoutes.findIndex(
        (route) => !!matchRoute(route, currentRoute)
      );
      return foundJourneyIndex !== -1;
    });
  }

  // Current route not found at all, reset and exit
  if (currentJourneyIndex === -1) {
    resetUserJourneyData();
    return;
  }

  // Ensure that the current route is found in the nested journey
  // For the first time it will be available. From the next time the below
  // check will determine whether journey is being followed
  const foundJourneyIndex = currentJourneyRoutes[currentJourneyIndex].findIndex(
    (route) => !!matchRoute(route, currentRoute)
  );
  if (foundJourneyIndex === -1) {
    resetUserJourneyData();
  }
}

type RegisterUserJourneyParams = {
  startRoute: string;
  routes: UserJourneyRoutes;
  clearCache?: () => void;
};

export function registerUserJourney({
  startRoute,
  routes,
  clearCache,
}: RegisterUserJourneyParams) {
  const routesChanged = haveRoutesChanged(routes);
  if (routesChanged || startingRoute !== startRoute) {
    // New journey is registered, remove the cache for the old one
    resetUserJourneyData();
  }

  startingRoute = startRoute;
  currentJourneyRoutes = routes;
  currentJourneyClearCache = clearCache || currentJourneyClearCache;

  if (routesChanged) {
    onRouteChange({ currentRoute: startRoute });
  }
}

type IsCurrentRoutePartOfJourneyParams = {
  currentRoute: string;
};

function isCurrentRoutePartOfJourney({
  currentRoute,
}: IsCurrentRoutePartOfJourneyParams) {
  if (currentRoute === startingRoute) {
    return true;
  }

  const foundJourney = currentJourneyRoutes.find((journeyRoutes) => {
    const foundJourneyIndex = journeyRoutes.find(
      (route) => !!matchRoute(route, currentRoute)
    );
    return foundJourneyIndex;
  });

  return !!foundJourney;
}

type SetUserJourneyDataParamsOptions = {
  merge: boolean;
};

type SetUserJourneyDataParams<T extends JavaScriptObject> = {
  data: Partial<T> | undefined;
  options?: SetUserJourneyDataParamsOptions;
};

export function setUserJourneyData<T extends JavaScriptObject>({
  data,
  options: { merge } = { merge: false },
}: SetUserJourneyDataParams<T>) {
  const partOfJourney = isCurrentRoutePartOfJourney({
    currentRoute: window.location.pathname,
  });
  if (!partOfJourney) {
    return;
  }

  let dataForMerge: JavaScriptObject | undefined = undefined;
  if (merge) {
    dataForMerge = currentJourneyData;
  }

  currentJourneyData = { ...dataForMerge, ...data };
}

export function getUserJourneyData<T extends JavaScriptObject>() {
  if (!currentJourneyData) {
    return undefined;
  }

  return currentJourneyData as Partial<T>;
}
