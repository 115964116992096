import {
  DAY_SEARCH_PARAM,
  FETCH_MY_TASKS_SEARCH_PARAM,
  IMPACT_CATEGORY_SEARCH_PARAM,
} from "../../utils/constants/page";
import { getDateFromStringifiedDate } from "../../utils/helpers/date-helpers";
import { GlobalFilters } from "../../utils/types/filter-types";
import { getCurrentRouteAsUrl } from "../../utils/helpers/navigation-helper";
import { FILTER_IMPACT_MAPPER } from "../../utils/constants/app-filters";
import { DEFAULT_FILTERS } from "../../utils/constants/app-filters";
import {
  getFiltersFromNativeStorage,
  persistFiltersToCacheAndNativeStorage,
} from "../../utils/helpers/filters-helper";

const keysToCheck: {
  [Property in keyof GlobalFilters]-?: {
    searchParamKey: string;
  };
} = {
  day: {
    searchParamKey: DAY_SEARCH_PARAM,
  },
  fetchMyTasks: {
    searchParamKey: FETCH_MY_TASKS_SEARCH_PARAM,
  },
  impactCategory: {
    searchParamKey: IMPACT_CATEGORY_SEARCH_PARAM,
  },
};

function convertQueryParamsToFilters() {
  const urlParams = new URLSearchParams(window.location.search);
  const modifiedFilters = Object.keys(keysToCheck).reduce<
    GlobalFilters & { routeHasFilters: boolean }
  >(
    function (cumulativeObject, keyToCheck) {
      const filterKey = keyToCheck as keyof GlobalFilters;

      const { searchParamKey } = keysToCheck[filterKey];
      const urlValue = urlParams.get(searchParamKey);
      if (!urlValue) {
        return cumulativeObject;
      }

      switch (filterKey) {
        case "impactCategory": {
          if (!FILTER_IMPACT_MAPPER.has(urlValue)) {
            break;
          }
          cumulativeObject[filterKey] = urlValue;
          cumulativeObject.routeHasFilters = true;
          break;
        }
        case "fetchMyTasks": {
          if (urlValue) {
            cumulativeObject[filterKey] = true;
            cumulativeObject.routeHasFilters = true;
          }
          break;
        }
        case "day": {
          const dateFromUrl = getDateFromStringifiedDate({
            stringifiedDate: urlValue,
          });
          if (!dateFromUrl || Number.isNaN(dateFromUrl.getTime())) {
            break;
          }
          cumulativeObject[filterKey] = dateFromUrl;
          cumulativeObject.routeHasFilters = true;
          break;
        }
        default:
          break;
      }

      return cumulativeObject;
    },
    {
      routeHasFilters: false,
      ...DEFAULT_FILTERS,
    }
  );
  return modifiedFilters;
}

function deleteFiltersFromCurrentRoute() {
  const currentUrl = getCurrentRouteAsUrl();
  Object.keys(keysToCheck).forEach(function (keyToCheck) {
    const filterKey = keyToCheck as keyof GlobalFilters;
    const { searchParamKey } = keysToCheck[filterKey];
    currentUrl.searchParams.delete(searchParamKey);
  });
  return currentUrl;
}

function rehydrateFiltersFromRouteToCache() {
  const globalFiltersWithFlag = convertQueryParamsToFilters();
  const { routeHasFilters, ...globalFilters } = globalFiltersWithFlag;
  if (!routeHasFilters) {
    return false;
  }

  persistFiltersToCacheAndNativeStorage(globalFilters);
  const updatedUrl = deleteFiltersFromCurrentRoute();
  window.history.replaceState(null, "", updatedUrl);
  return true;
}

function rehydrateFromSessionStorage() {
  const globalFilters = getFiltersFromNativeStorage();
  if (!globalFilters) {
    return;
  }

  persistFiltersToCacheAndNativeStorage(globalFilters);
}

export function rehydrateFiltersToCache() {
  const rehydrateFromRoute = rehydrateFiltersFromRouteToCache();
  if (rehydrateFromRoute) {
    return;
  }

  rehydrateFromSessionStorage();
}
