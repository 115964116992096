import styles from "./AppLoader.module.scss";

const LOADER_ID = "full-page-loader";

function attachLoaderToBody() {
  const loaderElement = document.createElement("div");
  loaderElement.setAttribute("class", styles.loader);

  const textElement = document.createElement("span");
  textElement.innerText = "Setting up the app on your browser";
  textElement.setAttribute("class", styles.loaderText);

  const loaderContainerElement = document.createElement("div");
  loaderContainerElement.setAttribute("id", LOADER_ID);
  loaderContainerElement.classList.add(
    styles.loaderContainer,
    styles.column,
    styles.horizontalCenter,
    styles.verticalCenter,
    "base-page-container"
  );

  loaderContainerElement.appendChild(loaderElement);
  loaderContainerElement.appendChild(textElement);

  // IMP gatsby_upgrade: Verify when upgrading gatsby
  document.getElementById("___gatsby")?.appendChild(loaderContainerElement);
}

function removeLoaderFromBody() {
  const loaderContainerElement = document.getElementById(LOADER_ID);
  if (!loaderContainerElement) {
    return;
  }

  document.getElementById("___gatsby")?.removeChild(loaderContainerElement);
}

export function showLoaderWithDelay() {
  const timer = window.setTimeout(attachLoaderToBody, 300);
  return function () {
    window.clearTimeout(timer);
    removeLoaderFromBody();
  };
}
