import { DISPLAY_TOAST_CACHE_KEY } from "../constants/app-common";
import { DisplayToastCache, DisplayToastCacheItem } from "../types/app-types";
import { readFromCache, writeToCache } from "./cache-listeners";

type AddToastNotificationParams = {
  toastItem: DisplayToastCacheItem;
};

export function addToastNotification({
  toastItem,
}: AddToastNotificationParams) {
  const toastData = readFromCache<DisplayToastCache>({
    cacheKey: DISPLAY_TOAST_CACHE_KEY,
  });
  const updatedToasts = [...(toastData?.data?.toasts ?? [])];
  const existingToastIds = new Set(updatedToasts.map(({ id }) => id));
  if (existingToastIds.has(toastItem.id)) {
    return;
  }

  updatedToasts.push(toastItem);
  writeToCache<DisplayToastCache>({
    cacheKey: DISPLAY_TOAST_CACHE_KEY,
    data: {
      toasts: updatedToasts,
    },
  });
}

type RemoveToastNotificationParams = {
  toastItemId: DisplayToastCacheItem["id"];
};

export function removeToastNotification({
  toastItemId,
}: RemoveToastNotificationParams) {
  const toastData = readFromCache<DisplayToastCache>({
    cacheKey: DISPLAY_TOAST_CACHE_KEY,
  });
  const toastsToFilter = toastData?.data?.toasts ?? [];
  const filteredToasts = toastsToFilter.filter(({ id }) => toastItemId !== id);
  writeToCache<DisplayToastCache>({
    cacheKey: DISPLAY_TOAST_CACHE_KEY,
    data: {
      toasts: filteredToasts,
    },
  });
}
