import React from "react";
import { string, func } from "prop-types";
import styles from "./SvgIcon.module.scss";
import {
  ERROR_STATUS,
  EXTRA_LARGE_SIZE,
  EXTRA_SMALL_SIZE,
  INFO_STATUS,
  LARGE_SIZE,
  MEDIUM_SIZE,
  SMALL_SIZE,
  SUCCESS_STATUS,
  WARN_STATUS,
} from "../../utils/constants/component-configs";
import { createClasses } from "../../utils/helpers/browser-helper";

export type SVGIconProps = {
  iconSize?: string;
  iconStatus?: string;
  // TODO SEO consideration
  semantic?: boolean;
  iconProps?: React.SVGProps<SVGSVGElement>;
  children: (params: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

export default function SVGIcon({
  iconSize = SMALL_SIZE,
  iconStatus,
  semantic,
  iconProps,
  children,
}: SVGIconProps) {
  const iconClasses = [styles.icon];
  if (iconProps?.className) {
    iconClasses.push(iconProps.className);
  }

  switch (iconSize) {
    case EXTRA_SMALL_SIZE:
      iconClasses.push(styles.isExtraSmall);
      break;
    case SMALL_SIZE:
      iconClasses.push(styles.isSmall);
      break;
    case MEDIUM_SIZE:
      iconClasses.push(styles.isMedium);
      break;
    case LARGE_SIZE:
      iconClasses.push(styles.isLarge);
      break;
    case EXTRA_LARGE_SIZE:
      iconClasses.push(styles.isExtraLarge);
      break;
    default:
      break;
  }

  switch (iconStatus) {
    case SUCCESS_STATUS:
      iconClasses.push(styles.hasTextSuccess);
      break;
    case ERROR_STATUS:
      iconClasses.push(styles.hasTextDanger);
      break;
    case WARN_STATUS:
      iconClasses.push(styles.hasTextWarning);
      break;
    case INFO_STATUS:
      iconClasses.push(styles.hasTextInfo);
      break;
    default:
      break;
  }

  const svgComponentProps: React.SVGProps<SVGSVGElement> = {
    ...iconProps,
    className: createClasses(iconClasses),
  };

  return children(svgComponentProps);
}

SVGIcon.propTypes = {
  iconSize: string,
  iconStatus: string,
  children: func.isRequired,
};
