import { useEffect, useRef, useState } from "react";

type TimerProps = {
  countdownSeconds: number;
  startTimer: boolean;
  nextUpdateAtSeconds?: number;
  onCountdownComplete: () => void;
};

export default function useTimer({
  startTimer,
  countdownSeconds,
  onCountdownComplete,
  nextUpdateAtSeconds = 1,
}: TimerProps) {
  const [timerLeft, setTimerLeft] = useState(Math.max(countdownSeconds, 0));
  const intervalRef = useRef<number | undefined>();

  useEffect(() => {
    if (!startTimer) {
      return;
    }

    intervalRef.current = window.setInterval(function () {
      setTimerLeft((prevValue) => Math.max(0, prevValue - nextUpdateAtSeconds));
    }, nextUpdateAtSeconds * 1000);

    return function () {
      window.clearInterval(intervalRef.current);
    };
  }, [nextUpdateAtSeconds, startTimer]);

  useEffect(() => {
    if (!startTimer) {
      return;
    }

    if (timerLeft === 0) {
      window.clearInterval(intervalRef.current);
      onCountdownComplete();
    }
  }, [startTimer, timerLeft, onCountdownComplete]);

  return { secondsLeft: timerLeft };
}
