import React from "react";
import BugIcon from "../../assets/svgs/solid-bug.inline.svg";
import ErrorBox from "../../atoms/error-box/ErrorBox";
import useTokenStatus from "../../hooks/useTokenStatus";
import {
  ALIGN_CENTER,
  ERROR_STATUS,
  EXTRA_LARGE_SIZE,
  LARGE_SIZE,
  MEDIUM_SIZE,
  PRIMARY_STATUS,
} from "../../utils/constants/component-configs";
import { getHomePageUrl } from "../../utils/helpers/auth-helper";
import { createClasses } from "../../utils/helpers/browser-helper";
import ColumnAlign from "../../atoms/column-align/ColumnAlign";
import SVGIcon from "../../atoms/svg-icon/SvgIcon";
import styles from "./ErrorPage.module.scss";
import Button from "../../atoms/button/Button";

type ErrorPageProps = {
  errorText: string;
};

export default function ErrorPage({ errorText }: ErrorPageProps) {
  const tokenExists = useTokenStatus({ clientKey: "ERROR_PAGE" });

  function routeToHomePage() {
    window.location.replace(getHomePageUrl(tokenExists));
  }

  return (
    <ColumnAlign
      classes={createClasses([styles.mx4, "base-page-container"])}
      verticalAlign={ALIGN_CENTER}
      horizontalAlign={ALIGN_CENTER}
    >
      <SVGIcon iconSize={EXTRA_LARGE_SIZE} iconStatus={ERROR_STATUS}>
        {(svgProps) => <BugIcon {...svgProps} />}
      </SVGIcon>
      <ColumnAlign
        classes={createClasses([
          styles.container,
          styles.hasTextCentered,
          styles.mt4,
        ])}
        horizontalAlign={ALIGN_CENTER}
      >
        <p>
          We have encountered an unexpected issue. Please try again and if the
          issue persists, contact us. Mention the reason as: {errorText}
        </p>
        <Button
          buttonClick={routeToHomePage}
          buttonRole={PRIMARY_STATUS}
          sizes={MEDIUM_SIZE}
          classes={styles.mt2}
        >
          Go to home page
        </Button>
      </ColumnAlign>
    </ColumnAlign>
  );
}
