type LoadPolyFillParams = {
  polyfillScriptPath: string;
};

export default function loadPolyfill({ polyfillScriptPath }: LoadPolyFillParams) {
  return new Promise<void>((resolve, reject) => {
    const pollyfillScript = document.createElement("script");

    /*
        For browsers that honour this. There is no need to load this script with async or defer
        because until these scripts are loaded, further running muust be prevented
      */
    pollyfillScript.setAttribute("async", "false");
    pollyfillScript.setAttribute("defer", "false");
    pollyfillScript.src = polyfillScriptPath;
    pollyfillScript.onload = function () {
      resolve();
    };
    pollyfillScript.onerror = function () {
      reject();
    };
    document.head.appendChild(pollyfillScript);
    return;
  });
}
