export const CLIENT_ERROR_MSG =
  "Please check your internet connection and try again in some time. If the issue persists please contact us";

export const UNKOWN_ERROR_MESSAGE =
  "Something is not quite right. Contact us to get this resolved";

export const REQUEST_TIMEOUT_ERROR_MSG =
  "We are having trouble reaching our servers. Please try again in some time";

export const REQUEST_COMMUNICATION_ERROR_MSG =
  "We could not start processing your request. Please contact us to get this resolved";

export const RESPONSE_COMMUNICATION_ERROR_MSG =
  "We are unable to fetch the results of your operation. Please contact us to get this resolved";

export const DATA_STORE_UNREACHABLE_ERROR_MSG =
  "We are having trouble reaching our data stores, please try again after sometime. If the issue persists contact us to get this resolved";

export const DATA_STORE_FORMAT_ERROR_MSG =
  "We are having trouble extracting data from our stores. Please contact us to get this resolved";

export const UNEXPECTED_BAD_REQUEST_ERROR_MSG =
  "Encountered a communication error when performing your requested operation. Please try again after some time if the issue persists please contact us to get this resolved";

export const UNEXPECTED_INTERNAL_SERVICE_ERROR_MSG =
  "Encountered an unexpected internal error when processing your request. Please contact us to get this resolved";
