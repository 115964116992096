import loadPolyfill from "../../utils/helpers/load-polyfill";
import { getPolyFillForRoute } from "../../utils/helpers/polyfill-checkers";

export async function loadPolyFillForCurrentRoute() {
  const { polyFill } = getPolyFillForRoute(window.location.pathname || "/");
  const polyFillToLoad = polyFill.filter(({ polyfillChecker }) =>
    polyfillChecker()
  );
  if (polyFillToLoad.length === 0) {
    return;
  }

  const pollyFillLoadPromises = polyFillToLoad.map(({ polyfillScriptPath }) =>
    loadPolyfill({ polyfillScriptPath })
  );

  try {
    await Promise.all(pollyFillLoadPromises);
  } catch (loadPolyfillError) {
    throw new Error(
      "Failed to download missing features in your browser. Try again once your internet is back. You can also upgrade your browser to skip this setup step"
    );
  }
}
